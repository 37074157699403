import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import cat1010 from "../../../../assets/img/cat-1010.jpg";
import cat1020 from "../../../../assets/img/cat-1020.jpg";

export default function ConceptionDeLogo() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1 className={styles.h1}>Chèques</h1>
      </div>
      <div className={styles.row}>
        <div class={styles.catHg}>
          <div class={styles.catutl}>
            <div class={styles.catImg}>
              <Link to="/gerez/cheques/haute-securite/cheques-laser/">
                <img src={cat1010} alt="Chèques et produits Haute Sécurité" />
              </Link>
            </div>

            <div class="cat-desc-tl">
              <div class={styles.catTitle}>
                <span>Chèques</span>
                <Link to="/gerez/cheques/haute-securite/cheques-laser/">
                  Chèques et produits Haute Sécurité
                </Link>
              </div>
            </div>
          </div>

          <div class={styles.catutl}>
            <div class={styles.catImg}>
              <Link to="/gerez/cheques/cheques-informatiques/en-continu/">
                <img src={cat1010} alt="Chèques informatiques" />
              </Link>
            </div>

            <div class="cat-desc-tl">
              <div class={styles.catTitle}>
                <span>Chèques</span>
                <Link to="/gerez/cheques/cheques-informatiques/en-continu/">
                  Chèques informatiques
                </Link>
              </div>
            </div>
          </div>

          <div class={styles.catutl}>
            <div class={styles.catImg}>
              <Link to="/gerez/cheques/Chèques-manuscrits/">
                <img src={cat1020} alt="Chèques manuscrits" />
              </Link>
            </div>

            <div class="cat-desc-tl">
              <div class={styles.catTitle}>
                <span>Chèques</span>
                <Link to="/gerez/cheques/Chèques-manuscrits/">
                  Chèques manuscrits
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
