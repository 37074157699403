import React from "react";
import Layout from "../../../other/layout";
import Cheques from "../../../components/AC-StaticPages/Gerez/Cheques/Cheques";
import SEO from "../../../other/seo";

export default function logiciels() {
  return (
    <Layout>
      <SEO title="Logiciels" />

      <Cheques />
    </Layout>
  );
}
